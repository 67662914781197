import axios from "axios";

const instance = axios.create({
  baseURL: "https://ko6jhfl2gj.execute-api.us-east-1.amazonaws.com/api/",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "ZBNM7MgR8I2xSBkvRgwYT4FA8VdEJxTU64pbNJfO"
  }

});

export default instance;
