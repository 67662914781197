<script>
import {
  POINT_MARKER_ICON_CONFIG1,
  POINT_MARKER_ICON_CONFIG,
  POINT_MARKER_ICON_BLUE,
  POINT_MARKER_ICON_GREEN
} from "../constants/mapSettings";

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    labelText: Number,
    type: Number,
    designMode: Number
  },

  mounted() {
    const { Marker } = this.google.maps;
    let iconMarker = null;
    if (this.type === 0) {
      iconMarker =
        this.designMode === 0
          ? POINT_MARKER_ICON_CONFIG1
          : POINT_MARKER_ICON_GREEN;
      new Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        icon: iconMarker,
        label: {
          text: this.labelText.toString(),
          color: "white",
          fontSize: "20px"
        }
      });
    } else {
      iconMarker =
        this.designMode === 0
          ? POINT_MARKER_ICON_CONFIG
          : POINT_MARKER_ICON_BLUE;
      new Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        icon: iconMarker //"https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png",
      });
    }
  },
  render() {
    return {};
  }
};
</script>