
import colorPalette from "@/constants/colorPalette";

const {
  COLOR_LANDSCAPE,
  COLOR_BORDERS,
  COLOR_WATER,
  COLOR_LINE,
  COLOR_POINT_FILL,
  COLOR_SELECTED_POINT
} = colorPalette;

const COLORS = {
  BORDERS: COLOR_BORDERS,
  LANDSCAPE: COLOR_LANDSCAPE,
  LINE: COLOR_LINE,
  POINT: COLOR_SELECTED_POINT,
  POINT_FILL: COLOR_POINT_FILL,
  WATER: COLOR_WATER
};

const POINT_MARKER_ICON_CONFIG = {
  path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
  strokeOpacity: 1,
  strokeWeight: 1,
  strokeColor: "white",
  fillColor: "#2DB34B",
  fillOpacity: 1,
  scale: 4
};

const POINT_MARKER_ICON_CONFIG1 = {
  path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
  strokeOpacity: 0.7,
  strokeWeight: 0,
  strokeColor: COLORS.POINT,
  fillColor: COLORS.POINT_FILL,
  fillOpacity: 1,
  scale: 4
};
const POINT_MARKER_ICON_BLUE = {
  path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
  strokeOpacity: 0.7,
  strokeWeight: 0,
  strokeColor: COLORS.POINT,
  fillColor: "#56ccf2",
  fillOpacity: 1,
  scale: 4
};

const POINT_MARKER_ICON_GREEN = {
  path: "M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0",
  strokeOpacity: 0.7,
  strokeWeight: 0,
  strokeColor: COLORS.POINT,
  fillColor: "#F90E1F",
  fillOpacity: 1,
  scale: 4,
  border: "10px solid #ffffff"
};
const LINE_SYMBOL_CONFIG = {
  path: "M 0,-2 0,2",
  strokeOpacity: 1,
  strokeWeight: 2,
  scale: 1
};

const LINE_PATH_CONFIG = {
  clickable: false,
  geodesic: false,
  strokeOpacity: 0,
  strokeColor: COLORS.LINE,
  icons: [
    {
      icon: LINE_SYMBOL_CONFIG,
      repeat: "10px"
    }
  ]
};

const mapSettings = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: "cooperative",
  //   backgroundColor: COLORS.LANDSCAPE,
  mapTypeControl: false,
  zoomControlOptions: {
    style: "SMALL"
  },
  zoom: 8,
  minZoom: 2,
  maxZoom: 50,
  styles: [
    {
      featureType: "water",
      stylers: [
        { hue: COLORS.WATER },
        { saturation: -63.2 },
        { lightness: 38 },
        { gamma: 1 }
      ]
    },
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }]
    }
  ]
};

export {
  mapSettings,
  LINE_PATH_CONFIG,
  POINT_MARKER_ICON_CONFIG,
  POINT_MARKER_ICON_CONFIG1,
  POINT_MARKER_ICON_BLUE,
  POINT_MARKER_ICON_GREEN
};
