<template>
  <div v-if="!isLoading" >
    <div >
      <GoogleMapLoader
        :mapConfig="mapConfig"
        apiKey="AIzaSyDALNYzbn_4r_xHfEYOS9y2a0wqviu73YI"
      >
        <template slot-scope="{ google, map }">
          <GoogleMarker
            v-for="marker in markers"
            :key="marker.id"
            :marker="marker"
            :google="google"
            :map="map"
            :labelText="marker.id"
            :type="0"
            :designMode="designMode"
          />
          <GoogleMarker
            v-if="parentData"
            :marker="{
              position: { lat: +parentData.lat, lng: +parentData.long }
            }"
            :google="google"
            :map="map"
            :labelText="2"
            :type="1"
            :designMode="designMode"
          />
        </template>
      </GoogleMapLoader>
      <div class="bottomMap" >
        <div class="rowBottom">
          <div class="circleContainer">
            <div class="circle green">
              <div class="innerCircle"></div>
            </div>
          </div>

          <p class="textBottom" >
            Este eres tu en el mapa
          </p>
        </div>
        <div class="rowBottom">
          <div class="circleContainer">
            <div class="circle outline-green">
              <div class="innerCircle">Nº</div>
            </div>
          </div>

          <p class="textBottom" >
            Establecimientos con vacantes disponibles que te pueden interesar
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMapLoader from "./GoogleMapLoader.vue";
import GoogleMarker from "./GoogleMarker.vue";
import { mapSettings } from "../constants/mapSettings";

export default {
  components: {
    GoogleMapLoader,
    GoogleMarker
  },
  props: {
    schools: Array,
    designMode: Number,
    parentData: Object
  },
  data() {
    return {
      markers: [],
      isLoading: true,
      textContent: {
        title: {
          0: "Acá puedes ver algunas instituciones educativas cerca de tu hogar",
          1: "Opa, encontrei algumas escolas que podem te  interessar"
        },
        subTitleOne: {
          0: "En el siguiente ",
          1: "Aqui embaixo, "
        },
        subTitleTwo: {
          0: "mapa",
          1: "nesse mapa,"
        },
        subTitleThree: {
          0: " puedes ver la ",
          1: " você pode ver a "
        },
        subTitleFour: {
          0: "ubicación de los establecimientos educacionales en tu zona",
          1: "localização das escolas mais perto de você"
        }
      }
    };
  },
  mounted() {
    this.markers = this.schools.map((school, index) => ({
      id: index + 1,
      position: { lat: +school.lat, lng: +school.long }
    }));

    this.isLoading = false;
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        // center: { lat: -0.161604, lng: -78.484974 }
        center: { lat: +this.parentData.lat, lng: +this.parentData.long }
      };
    }
  }
};
</script>

<style scoped>
.mapContainer {
  width: 100%;

  background-color: white;
  display: flex;
  align-items: center;

  flex-direction: column;
}

.title {
  padding: 50px 0;
  font-size: 30px;
  line-height: 50px;
  color: var(--color-grey-light-2);
}
.mapText {
  font-size: 2.5rem;
  line-height: 3.5rem;
  color: var(--color-grey-light-2);
  margin-inline: 30%;
  padding-top: 4rem;
  padding-bottom: 6rem;
}
.boldFont {
  font-family: "gotham-bold";
}
.bottomMap {
  width: 100vw;
  height: 120px;
  background-color:  rgba(251, 225, 194, 1);
  display: flex;
  align-items: center;
  padding-left: 3rem;
}
.optionalBg {
  background-color: var(--color-yellow);
}

.circleContainer {
  padding-right: 0.5rem;
}
.circle {
  width: 3.8rem;
  height: 3.8rem;
  background-color: #F90E1F;
  border-radius: 50%;
  margin-top: 0.6rem;
  position: relative;
  margin-bottom: 2rem;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.orange {
  background-color: var(--color-orange);
}
.green {
  background-color: #57ccf2;
}
.outline-green {
  border: 5px solid #F90E1F;
}
.blue {
  background-color: var(--color-blue-3);
}
.innerCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: white;
}
.rowBottom {
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.textBottom {
  font-size: 15px;
  line-height: 25px;
  color: #6A5F65;
  text-align: left;
}
.optionalText {
  color: var(--color-grey-dark-1);
}
@media (max-width: 500px) {
  .mapContainer {
    width: 100%;
  }

  .innerCircle {
    font-size: 10px;
    color: white;
  }

  .circle {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    /* margin-top: 0.6rem; */
    position: relative;
    margin-bottom: 0.5rem;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

  .title {
    padding-top: 5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-inline: 5%;
  }
  .mapText {
    font-size: 1.8rem;
    line-height: 2.5rem;

    margin-inline: 10%;
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
  .textBottom {
    font-size: 12px;
    line-height: 25px;
    padding-left: 0;
  }
  .bottomMap {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 10px;
  }
}
</style>
